

























type TimeTicksMapData = {
  got: Record<string, Set<number>>
  give: Record<string, Set<number>>
}

import { ChatMetrics } from '@/includes/types/DashboardExtended/types'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import { sum } from 'lodash'

@Component({
  components: {
    VueApexCharts,
    TagsRow,
    HelpMessage  }
})
export default class UniqueUsersReputationChart extends Vue {
  @Prop() metrics!: ChatMetrics

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        height: 350,
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-success), 1)' ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.timeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
      grid: {
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        },
      }
    }
  }

  get prepareReputationData() {
    const { timeTicks } = this.timeTicks()

    const timeTicksMap = timeTicks.reduce((map: TimeTicksMapData, current) => {
      map.got[current] = new Set()
      map.give[current] = new Set()

      return map
    }, { got: {}, give: {} })

    this.metrics.reputation_users.forEach(record => {
      const formattedTime = moment(record.time).format('YYYY-MM-DD')

      if (timeTicksMap.got[formattedTime]) {

        timeTicksMap.got[formattedTime].add(record.target_user_id)
        timeTicksMap.give[formattedTime].add(record.actor_user_id)

      }
    })

    const gotValues = Object.values(timeTicksMap.got).map(date => date.size);
    const giveValues = Object.values(timeTicksMap.give).map(date => date.size);

    return {
      gotValues,
      giveValues
    }
  }

  get totalValues() {
    const { giveValues, gotValues } = this.prepareReputationData

    return [
      {
        title: 'dashboard_statistics_total_reputation_actors',
        value: sum(giveValues),
        icon: 'export',
        color: 'rgb(var(--a-primary))'
      },
      {
        title: 'dashboard_statistics_total_reputation_targets',
        value: sum(gotValues),
        icon: 'import',
        color: 'rgb(var(--a-success))'
      }
    ]
  }

  get chartData() {
    const { giveValues, gotValues } = this.prepareReputationData

    return [
      {
        name: this.$t(`dashboard_statistics_reputation_actors`).toString(),
        data: giveValues,
      },
      {
        name: this.$t(`dashboard_statistics_reputation_targets`).toString(),
        data: gotValues
      },
    ]
  }

  timeTicks() {
    return fromToTicks(this.metrics.period_start, this.metrics.period_end)
  }
}
