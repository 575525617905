

















import { ChatMetrics } from "@/includes/types/DashboardExtended/types";

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList';
import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";
import { ApexChartLocales, ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from "moment";
import { ApexOptions } from "apexcharts";
import VueApexCharts from "vue-apexcharts";

@Component({
  components: {
    VueApexCharts,
    ConfigField
  }
})
export default class DashboardReactionsStat extends Vue {
  @Prop() statistics!: ChatMetrics['reactions']

  chartData: ApexChartSeries | null = null

  get fromTo() {
    const startOfMonth = moment().startOf('month')

    const from = moment(startOfMonth.clone().add('-1', 'year'), 'YYYY-MM-DD').format('YYYY-MM-DD')
    const momentTo = moment(startOfMonth, 'YYYY-MM-DD').format('YYYY-MM-DD')

    return fromToTicks(from, momentTo, { unit: 'month', amount: 1, format: 'YYYY-MM-DD' })
  }

  get chartOptions(): ApexOptions {
    return {
      dataLabels: {
        enabled: false,
      },
      chart: {
        animations: {
          enabled: false
        },
        type: 'bar',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        defaultLocale: this.$i18n.locale,
        locales: ApexChartLocales
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-warning), 1)' ],
      xaxis: {
        labels: {
          formatter(value: string, timestamp?: number, opts?: any): string | string[] {
            return moment(value).format('MMM YYYY')
          }
        },
        categories: this.fromTo.timeTicks,
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      grid: {
        show: true,
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        },
      },
    }
  }

  created() {
    const timeSplitRecords = this.statistics.map(r => {
      return {
        ...r,
        time: r.time.split(' ')[0]
      }
    })

    seriesByMetricInList(this.$i18n, timeSplitRecords, [ 'msg_count', 'reaction_count' ], this.fromTo)
      .calcSeries()
      .then(series => {
        this.chartData = series
      })
  }
}
