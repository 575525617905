








































import UniqueUsersReputationChart from './MetricReputationCharts/UniqueUsersReputationChart.vue'
import { ChatMetrics } from '../../../includes/types/DashboardExtended/types'
import ReputationSumChart from './MetricReputationCharts/ReputationSumChart.vue'
import ReputationUsersTop from './MetricReputationCharts/ReputationUsersTop.vue'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";

@Component({
  components: {
    NotAvailableOptionsOverlay,
    ReputationUsersTop,
    ReputationSumChart,
    UniqueUsersReputationChart,
  }
})
export default class MetricReputation extends Mixins(TariffsTagsHelper) {
  @Prop() metrics!: ChatMetrics

  @Prop() isReputationEnabled!: boolean
}
