




















import { ChatMetrics } from '@/includes/types/DashboardExtended/types'

import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import { groupBy, sumBy } from 'lodash'

@Component({
  components: {
    VueApexCharts,
    HelpMessage
  }
})
export default class ReputationSumChart extends Vue {
  @Prop() metrics!: ChatMetrics

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: 'area',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
        animations: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.timeTicks().timeTicks.map(t => moment(t).format('D MMM')),
        tooltip: {
          enabled: false
        }
      },
      stroke: {
        curve: 'smooth',
        width: 2.5,
      },
      grid: {
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        },
      }
    }
  }

  get prepareReputationData() {
    const { timeTicks } = this.timeTicks()

    const groupedTime = groupBy(this.metrics.reputation_users, "time")

    return timeTicks.reduce((valuesList:Array<number>, current, index) => {
      valuesList[index] = sumBy(groupedTime[current], "sum")

      return valuesList
    }, [])
  }

  get chartData(): ApexChartSeries {
    return [ {
      name: "Репутация",
      data: this.prepareReputationData
    }, ]
  }

  timeTicks() {
    return fromToTicks(this.metrics.period_start, this.metrics.period_end)
  }
}
