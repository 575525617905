





































































































































import { ChatMetrics } from '@/includes/types/DashboardExtended/types'
import MetricTriggers from './MetricComponents/MetricTriggers.vue'
import MetricFilters from './MetricComponents/MetricFilters.vue'
import MetricModeration from './MetricComponents/MetricModeration.vue'
import MetricReputation from './MetricComponents/MetricReputation.vue'
import { filtersStatistics } from '@/components/CommunityDashboard/logic/metrics/filtersStatistics'
import MetricCard from '@/components/CommunityDashboard/components/MetricCard.vue'
import { triggersStatistics } from '@/components/CommunityDashboard/logic/metrics/triggersStatistics'
import { sumCombinedRatesValues } from '@/components/CommunityDashboard/logic/sumCombinedRatesValues'
import ReputationMessageHandlerModule from '@/includes/logic/Modules/models/modules/ReputationMessageHandlerModule/ReputationMessageHandlerModule'
import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { sumBy } from 'lodash'

enum MetricType {
  Filter,
  Triggers,
  Reputation,
  Moderation,
}

@Component({
  components: {
    MetricCard,
    MetricModeration,
    MetricTriggers,
    MetricFilters,
    MetricReputation
  },
  data() {
    return {
      MetricType
    }
  }
})
export default class DashboardMetrics extends Mixins(TariffsTagsHelper) {
  @Prop() metrics!:ChatMetrics

  currentViewType: MetricType = MetricType.Filter

  metricModalOpen = false

  filtersStat = filtersStatistics(this.metrics)
  triggersStat = triggersStatistics(this.metrics)

  get isReputationEnabled() {
    return !!ModuleManagerState?.modules?.find(module => module instanceof ReputationMessageHandlerModule)
  }

  get activeTriggers() {
    return this.$store.state.chatState.chat.triggers
  }

  get totalReputation() {
    return sumBy(this.metrics.reputation_users, 'sum')
  }

  get manualModerationSum() {
    const manualAdminModerationTotal = sumCombinedRatesValues(this.metrics.manual_admin_moderation)
    const triggerPunishTotal = sumCombinedRatesValues(this.metrics.trigger_punish || [])
    const facecontrolTotal = sumCombinedRatesValues(this.metrics.facecontrol)

    return manualAdminModerationTotal + triggerPunishTotal + facecontrolTotal
  }

  get titleByCurrentViewType() {
    switch (this.currentViewType) {
      case MetricType.Triggers:
        return this.$t('modal_metric_type_triggers_title').toString()
      case MetricType.Reputation:
        return this.$t('modal_metric_type_reputation_title').toString()
      case MetricType.Moderation:
        return this.$t('modal_metric_type_moderation_title').toString()
      case MetricType.Filter:
        return this.$t('modal_metric_type_filter_title').toString()
      default:
        return ""
    }
  }

  changeView(view: MetricType) {
    this.currentViewType = view

    this.metricModalOpen = true
  }

  created() {
    filtersStatistics(this.metrics)
  }
}
