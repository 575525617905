















import { ChatMetrics, ChatMetricsOptional } from '@/includes/types/DashboardExtended/types'
import { sumCombinedRatesValues } from '@/components/CommunityDashboard/logic/sumCombinedRatesValues'
import { TagRowItem } from '@/components/TagRow/types'
import combinedRateSeries from '@/components/CommunityDashboard/logic/combinedRateSeries'
import TagsRow from '@/components/TagRow/TagsRow.vue'

import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'

@Component({
  components: {
    TagsRow,
    VueApexCharts
  }
})
export default class BlockingStatNew extends Vue {
  @Prop() metrics!:ChatMetrics

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        height: 350,
        animations: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-danger), 1)', ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.timeTicks().timeTicks.map(t => moment(t).format('D MMM')),
      },
      grid: {
        padding: {
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
        },
      }
    }
  }

  get chartSeries() {
    const fields: Pick<ChatMetricsOptional, "facecontrol" | "guard"> = {
      facecontrol: [ ...this.metrics?.facecontrol ?? [] ],
      guard: [ ...this.metrics?.guard ?? [] ],
    }

    return combinedRateSeries(fields, this.timeTicks())
  }

  get calculateTotals():Array<TagRowItem> {
    const facecontrolTotal = sumCombinedRatesValues(this.metrics.facecontrol)
    const guardTotal = sumCombinedRatesValues(this.metrics.guard)

    return [
      {
        title: 'dashboard_statistics_facecontrol_total',
        value: facecontrolTotal,
        color: 'rgba(var(--a-primary), 1)',
        icon: 'smile',
      },
      {
        title: 'dashboard_statistics_guard_total',
        value: guardTotal,
        color: 'rgba(var(--a-success), 1)',
        icon: 'safety-certificate',
      },
    ]
  }

  timeTicks() {
    return fromToTicks(this.metrics.period_start, this.metrics.period_end)
  }
}
